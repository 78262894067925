@use "src/styles/palette";

@keyframes pump {
  0%,
  100% {
    transform: scale(1);
  }

  32% {
    transform: scale(1.3);
  }

  50% {
    transform: scale(1);
  }

  72% {
    transform: scale(1.3);
  }

  94% {
    transform: scale(1);
  }
}

.favourite_button {
  background-color: palette.grey(light);
  width: 2.8125rem;
  height: 2.8125rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 2rem;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  border: 2px solid palette.grey(border);

  &:hover {
    background-color: palette.grey(base);
  }
}

.heart {
  width: 1.4rem;
  height: 1.4rem;

  &.animate {
    @extend .heart;

    animation: pump 1s;
  }
}

.fill {
  transition: opacity 0.25s ease-in-out;
  fill: palette.brand();
  opacity: 0;

  &.animate {
    @extend .fill;

    opacity: 1;
  }
}

.counter {
  position: absolute;
  top: 0;
  right: 0;
  background-color: black;
  width: 1rem;
  height: 1rem;
  color: white;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  border-radius: 1rem;
  font-size: 9px;
}
